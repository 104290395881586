import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';
import SectionContainer from 'src/sections/shared/SectionContainer';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import { openIntercom } from 'src/utils';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(1),
    marginBottom: spacing(10),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  secondaryText: {
    maxWidth: pxToRem(600),
    margin: spacing(8),
    whiteSpace: 'pre-line',
  },
  icon: {
    alignSelf: 'center',
    height: pxToRem(120),
  },
});

const GetStarted: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      className={classes.container}
      backgroundShape={6}
      background="light-blue"
      backgroundShapeBlendMode="overlay"
    >
      <Typography variant="h2" color="blue" underline centered style={{ maxWidth: pxToRem(900) }}>
        Ready to Grow Your Business with Google Ads?
      </Typography>
      <Typography variant="p1" color="blackish" centered className={classes.secondaryText}>
        Take your marketing to the next level with our expert Google Ads Management services.
        Contact us today to find the right ads strategy for you.
      </Typography>
      <Button
        variant="filled"
        color="teal"
        onClick={openIntercom}
        className={classes.button}
      >
        Get started
      </Button>
    </SectionContainer>
  );
};

export default GetStarted;
