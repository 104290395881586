import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { createUseStyles } from 'react-jss';
import LaptopScreenshot from 'src/components/LaptopScreenshot';
import { breakpoint } from 'src/theme';
import { assetLink } from 'src/utils';
import RightLeftChecklistSection from '../shared/RightLeftChecklist';

const useStyles = createUseStyles({
  video: {
    width: '85%',
    [breakpoint.down('md')]: {
      width: '100%',
    },
  },
  demo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  smallDemo: {
    marginTop: -70,
    marginBottom: 20,
    [breakpoint.up('md')]: {
      marginLeft: '25%',
    },
  },
});

const content1 = [
  <p>
    <strong>Proven Expertise:</strong>
    {' '}
    Let our Google Ads specialists optimize your ads to drive real results
  </p>,
  <p>
    <strong>Laser-Focused Targeting:</strong>
    {' '}
    Reach your customers with precisely chosen keywords that convert.
  </p>,
  <p>
    <strong>Dynamic Optimization:</strong>
    {' '}
    We keep your ads updated with continuous, data-driven adjustments.
  </p>,
  <p>
    <strong>24/7 Support:</strong>
    {' '}
    Get peace of mind with customer support, always ready to assist with your ads!
  </p>,
];
const content2 = [
  <p>
    <strong>Multi-Channel Domination:</strong>
    {' '}
    Maximize your impact with ads that captivate across Google search, email, Youtube, and websites!
  </p>,
  <p>
    <strong>AI-Enhanced Performance:</strong>
    {' '}
    Leverage cutting-edge AI to outsmart your competitors and boost ROI.
  </p>,
  <p>
    <strong>Flexible Budgeting:</strong>
    {' '}
    Scale your ad spend with confidence, knowing we\u2019ll optimize every dollar.
  </p>,
  <p>
    <strong>See the impact:</strong>
    {' '}
    Receive detailed monthly reports with data-driven insights and specific ad strategies.
  </p>,
];

const BuiltSection: React.FC = () => {
  const classes = useStyles();
  return (
    <RightLeftChecklistSection
      title="Grow Your Small Business with Expert Google Ads Management"
      rightContent={{
        title: 'Increase Your Sales with Expert\nManagement',
        checklist: content1,
        media: (
          <LaptopScreenshot src={assetLink('landing-ui/product-images/AdsDashboard.png')} className={classes.video} />
        ),
      }}
      leftContent={{
        title: 'Maximize Your Advertising Potential',
        checklist: content2,
        media: (
          <div className={classes.demo}>
            <StaticImage
              width={304}
              height={242}
              transformOptions={{ cropFocus: 'top' }}
              src="https://assets.bizwise.com/landing-ui/product-images/AdsDemo.png"
              alt="Programmer woman at computer"
              style={{
                borderRadius: 20,
                filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
              }}
              className={classes.smallDemo}
            />
            <StaticImage
              width={600}
              src="https://assets.bizwise.com/landing-ui/product-images/AdsDashboard2.png"
              alt="Programmer woman at computer"
              style={{
                borderRadius: 20,
                filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
              }}
            />
          </div>
        ),
      }}
    />
  );
};

export default BuiltSection;
