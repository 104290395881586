import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from 'src/components/core/Typography';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import { assetLink } from 'src/utils';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginBlock: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginBlock: spacing(10),
    },
  },
  icon: {
    height: pxToRem(60),
  },
});

const content: {
  iconSrc: string,
  label: string,
  details: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/BusinessServicesIcon.svg'),
    label: 'Expert Management',
    details: 'Our team of experts handles every aspect of your Google Ads campaigns, from setup to optimization, ensuring your ads always perform at their best.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/FastIcon.svg'),
    label: 'Tailored Ad Strategies',
    details: 'We create customized strategies that align with your business goals and budget, targeting the right audience every time.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/DataIcon.svg'),
    label: 'Data-Driven Strategy',
    details: 'Our approach is rooted in data. We analyze performance regularly and make informed adjustments to make money work hard for you.',
  },
];

const Dashboard: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <div className={classes.grid}>
        {content.map(({ iconSrc, label, details }) => (
          <div className="flex flex-col items-center" key={label}>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <Typography variant="h3" color="blue" centered style={{ marginTop: spacing(6) }}>
              {label}
            </Typography>
            <Typography variant="p1" color="blue" centered style={{ marginTop: spacing(2) }}>
              {details}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default Dashboard;
