import cx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from 'src/components/core/Typography';
import SectionContainer from 'src/sections/shared/SectionContainer';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import { assetLink } from 'src/utils';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacing(10),
  },
  text: {
    marginTop: spacing(8),
    marginBottom: spacing(10),
    [breakpoint.mobile()]: {
      marginBottom: spacing(10),
    },
  },
  grid: {
    columnGap: spacing(30),
    rowGap: spacing(8),
    [breakpoint.down('sm')]: {
      columnGap: spacing(5),
      rowGap: spacing(5),
    },
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: spacing(4),
    height: pxToRem(60),
    [breakpoint.mobile()]: {
      height: pxToRem(50),
    },
  },
});

const features: {
  iconSrc: string,
  label: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/SeoIcon.svg'),
    label: 'Continuous Ad\nOptimization',
  },
  {
    iconSrc: assetLink('landing-ui/icons/ChatIcon.svg'),
    label: 'Live Chat for\nHelp',
  },
  {
    iconSrc: assetLink('landing-ui/icons/AnalyticsIcon.svg'),
    label: 'Detailed Monthly\nReport',
  },
  {
    iconSrc: assetLink('landing-ui/icons/SchedulingIcon.svg'),
    label: 'Adjust Budget\nAnytime',
  },
  {
    iconSrc: assetLink('landing-ui/icons/BookingIcon.svg'),
    label: 'Tailored Ads\nStrategy',
  },
  {
    iconSrc: assetLink('landing-ui/icons/BloggingIcon.svg'),
    label: 'Expert-led Ads\nContent Creation',
  },
];

interface Props {
  businessType?: string,
}

const Features: React.FC<Props> = ({ businessType }) => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography variant="h2" color="blue" centered underline style={{ maxWidth: pxToRem(870) }}>
        Powerful Features Keeping Your Ads Ahead Of The Competition
      </Typography>
      <Typography
        variant="p1"
        color="blue"
        centered
        className={classes.text}
        style={{
          maxWidth: pxToRem(600),
        }}
      >
        Use as many or as little of these features as you want.
      </Typography>
      <div className={cx(classes.grid, 'grid grid-cols-3')}>
        {features.map(({ iconSrc, label }) => (
          <div className="flex flex-col items-center" key={label}>
            <img src={iconSrc} className={classes.icon} alt={`${label} Icon`} />
            <Typography variant="p3" color="blue" centered style={{ fontWeight: 600 }}>
              {label}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default Features;
