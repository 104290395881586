import React from 'react';
import ApiWrapper from 'src/components/ApiWrapper';
import Seo from 'src/components/Seo';
import Layout from 'src/layouts/Page';
import BuiltSection from 'src/sections/Ads/Built';
import DashboardSection from 'src/sections/Ads/Dashboard';
import Features from 'src/sections/Ads/Features';
import GetStarted from 'src/sections/Ads/GetStarted';
import HeroSection from 'src/sections/Ads/Hero';
import Pricing from 'src/sections/Ads/Pricing';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';

const AdsPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo
        title="Ads - Bizwise"
      />
      <HeroSection />
      <DashboardSection />
      <BuiltSection />
      <Features />
      <Pricing />
      <FaqSection types={[FaqTypes.ADS_MODULE]} />
      <GetStarted />
    </Layout>
  </ApiWrapper>

);

export default AdsPage;
